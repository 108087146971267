import React, { useState, useRef, useCallback, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { IconCamera, IconFocus, IconBrightnessUp, IconRotate } from '@tabler/icons-react';

const CustomWebcam = ({ onImageCapture, isFullscreen, setIsFullscreen, hidePreview }) => {
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [useBackCamera, setUseBackCamera] = useState(isMobile);
  const [deviceId, setDeviceId] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [hasFlash, setHasFlash] = useState(false);
  const [isFlashOn, setIsFlashOn] = useState(false);
  const [focusMode, setFocusMode] = useState('continuous');
  const [zoom, setZoom] = useState(1);
  const [capturedImage, setCapturedImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [blobPreview, setBlobPreview] = useState(null);
  const [error, setError] = useState(null);

  const initializeCamera = useCallback(async (selectedDeviceId = null) => {
    try {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }

      let constraints = {
        video: {
          facingMode: useBackCamera ? "environment" : "user",
          width: { ideal: 1920 },
          height: { ideal: 1080 }
        },
        audio: false
      };

      if (selectedDeviceId) {
        constraints.video.deviceId = { exact: selectedDeviceId };
      }

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;
      
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        
        await new Promise((resolve) => {
          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play().catch(console.error);
            resolve();
          };
        });

        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        setHasFlash('torch' in capabilities);

        setError(null);
      }
    } catch (err) {
      console.error('Camera initialization error:', err);
      setError(`Camera access failed: ${err.message}`);
    }
  }, [useBackCamera]);

  useEffect(() => {
    const setupCamera = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(device => device.kind === 'videoinput');
        setCameras(videoDevices);

        if (isMobile && useBackCamera) {
          const backCamera = videoDevices.find(device =>
            device.label.toLowerCase().includes('back') ||
            device.label.toLowerCase().includes('environment')
          );
          if (backCamera) {
            setDeviceId(backCamera.deviceId);
            await initializeCamera(backCamera.deviceId);
          } else {
            await initializeCamera();
          }
        } else {
          await initializeCamera();
        }
      } catch (err) {
        console.error('Camera setup error:', err);
        setError(`Failed to setup camera: ${err.message}`);
      }
    };

    setupCamera();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
      }
    };
  }, [initializeCamera, useBackCamera]);

  const toggleFlash = useCallback(async () => {
    if (hasFlash && streamRef.current) {
      const newFlashState = !isFlashOn;
      const track = streamRef.current.getVideoTracks()[0];
      try {
        await track.applyConstraints({
          advanced: [{ torch: newFlashState }]
        });
        setIsFlashOn(newFlashState);
      } catch (err) {
        console.error('Flash toggle failed:', err);
      }
    }
  }, [hasFlash, isFlashOn]);

  const toggleFocus = useCallback(async () => {
    if (streamRef.current) {
      const newFocusMode = focusMode === 'continuous' ? 'manual' : 'continuous';
      const track = streamRef.current.getVideoTracks()[0];
      try {
        await track.applyConstraints({
          advanced: [{ focusMode: newFocusMode }]
        });
        setFocusMode(newFocusMode);
      } catch (err) {
        console.error('Focus toggle failed:', err);
      }
    }
  }, [focusMode]);

  const handleZoom = useCallback(async (value) => {
    setZoom(value);
    try {
      if (streamRef.current) {
        const track = streamRef.current.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        if (capabilities?.zoom) {
          await track.applyConstraints({
            advanced: [{ zoom: value }]
          });
        }
      }
    } catch (error) {
      console.warn('Zoom not supported:', error);
    }
  }, []);

  const capture = useCallback(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      
      const ctx = canvas.getContext('2d');
      
      if (!useBackCamera && window.screen.orientation) {
        const orientation = window.screen.orientation.angle;
        if (orientation === 90 || orientation === -90) {
          canvas.width = video.videoHeight;
          canvas.height = video.videoWidth;
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((orientation * Math.PI) / 180);
          ctx.drawImage(video, -video.videoWidth / 2, -video.videoHeight / 2);
        } else {
          ctx.drawImage(video, 0, 0);
        }
      } else {
        ctx.drawImage(video, 0, 0);
      }

      const base64Image = canvas.toDataURL('image/jpeg', 1.0);
      setImgSrc(base64Image);
      setCapturedImage(base64Image);
      setShowPreview(true);
      setIsFullscreen(false);
    }
  }, [useBackCamera, setIsFullscreen]);

  const handleSendToBackend = useCallback(() => {
    if (capturedImage) {
      const base64ToBlob = (base64) => {
        const byteString = atob(base64.split(',')[1]);
        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        
        return new Blob([ab], { 
          type: 'image/jpeg',
          quality: 1.0
        });
      };

      const imageBlob = base64ToBlob(capturedImage);
      console.log('Image size:', imageBlob.size, 'bytes');
      setBlobPreview(URL.createObjectURL(imageBlob));
      onImageCapture(imageBlob);
    }
  }, [capturedImage, onImageCapture]);

  useEffect(() => {
    return () => {
      if (blobPreview) {
        URL.revokeObjectURL(blobPreview);
      }
    };
  }, [blobPreview]);

  return (
    <div className={`webcam-container ${isFullscreen ? 'fullscreen' : ''}`}>
      {error ? (
        <div className="error-message">
          {error}
          <button 
            className="retry-button"
            onClick={() => {
              setError(null);
              initializeCamera(deviceId);
            }}
          >
            Retry
          </button>
        </div>
      ) : !showPreview ? (
        <>
          <div className="webcam-wrapper">
            <video
              ref={videoRef}
              playsInline
              autoPlay
              className="camera-video"
              style={{
                transform: useBackCamera ? 'none' : 'scaleX(-1)'
              }}
            />
          </div>

          <div className="camera-controls">
            {hasFlash && (
              <button
                className={`control-button flash-button ${isFlashOn ? 'active' : ''}`}
                onClick={toggleFlash}
              >
                <IconBrightnessUp size={24} />
              </button>
            )}

            <button
              className={`control-button focus-button ${focusMode === 'continuous' ? 'active' : ''}`}
              onClick={toggleFocus}
            >
              <IconFocus size={24} />
            </button>

            <button className="control-button capture-button" onClick={capture}>
              <IconCamera size={32} />
            </button>

            <input
              type="range"
              min="1"
              max="10"
              step="0.1"
              value={zoom}
              onChange={(e) => handleZoom(parseFloat(e.target.value))}
              className="zoom-slider"
            />

            {cameras.length > 1 && (
              <button
                className="control-button"
                onClick={() => {
                  setUseBackCamera(!useBackCamera);
                  initializeCamera();
                }}
              >
                <IconRotate size={24} />
              </button>
            )}
          </div>
        </>
      ) : !hidePreview ? (
        <div className="preview-container">
          <div className="images-comparison">
            <div className="image-section original">
              <h3>Captured Image</h3>
              <div className="image-wrapper">
                <img 
                  src={capturedImage} 
                  alt="Original Captured"
                  style={{
                    width: 'auto',
                    height: 'auto',
                    maxWidth: '100%',
                    maxHeight: '80vh',
                    objectFit: 'contain'
                  }}
                />
              </div>
              <p>Click to expand</p>
            </div>
          </div>

          <div className="preview-controls">
            <button 
              className="control-button"
              onClick={() => {
                setShowPreview(false);
                setCapturedImage(null);
                if (blobPreview) {
                  URL.revokeObjectURL(blobPreview);
                  setBlobPreview(null);
                }
              }}
            >
              Retake
            </button>
            <button 
              className="control-button"
              onClick={handleSendToBackend}
            >
              Process Image
            </button>
          </div>
        </div>
      ) : null}

      <style jsx>{`
        .webcam-container {
          position: relative;
          width: 100%;
          height: ${isFullscreen ? '100vh' : 'auto'};
          overflow: hidden;
        }

        .webcam-container.fullscreen {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 9999;
          background: #000;
        }

        .webcam-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .camera-video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .camera-controls {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          padding: 20px;
          background: rgba(0, 0, 0, 0.5);
        }

        .control-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 50px;
          border-radius: 20%;
          border: none;
          background: rgba(255, 255, 255, 0.2);
          color: white;
          cursor: pointer;
          transition: all 0.3s ease;
        }

        .control-button:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        .control-button.active {
          background: #007bff;
        }

        .capture-button {
          width: 72px;
          height: 72px;
          background: #ff4444;
        }

        .capture-button:hover {
          background: #ff5555;
        }

        .zoom-slider {
          width: 100px;
          height: 4px;
          -webkit-appearance: none;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 2px;
          outline: none;
        }

        .zoom-slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: white;
          cursor: pointer;
        }

        .error-message {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(255, 0, 0, 0.1);
          padding: 20px;
          border-radius: 8px;
          text-align: center;
          color: white;
        }

        .retry-button {
          margin-top: 10px;
          padding: 8px 16px;
          background: #007bff;
          border: none;
          border-radius: 4px;
          color: white;
          cursor: pointer;
        }

        .retry-button:hover {
          background: #0056b3;
        }

        .preview-container {
          position: relative;
          width: 100%;
          min-height: 100vh;
          background: #fff;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .images-comparison {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 80px;
        }

        .image-section {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .image-section h3 {
            margin: 0 0 10px 0;
            color: #333;
          }

          .image-section p {
            margin: 10px 0;
            color: #666;
            font-size: 0.9em;
          }

          .image-wrapper {
            width: 100%;
            max-width: 800px;
            background: #f5f5f5;
            border: 1px solid #ddd;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            transition: all 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .image-wrapper:hover {
            transform: scale(1.01);
            box-shadow: 0 4px 15px rgba(0,0,0,0.1);
          }

          .preview-image {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 80vh;
            object-fit: contain;
          }

          .preview-controls {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            gap: 20px;
            padding: 20px;
            margin-left: 50px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
          }

          .preview-controls .control-button {
            padding: 12px 24px;
            border-radius: 25px;
            background: #007bff;
            color: white;
            border: none;
            cursor: pointer;
            font-size: 16px;
            transition: all 0.3s ease;
          }

          .preview-controls .control-button:hover {
            background: #0056b3;
          }

          @media (max-width: 768px) {
            .camera-controls {
              padding: 16px;
              gap: 16px;
            }

            .control-button {
              width: 90px;
              height: 60px;
            }

            .capture-button {
              width: 64px;
              height: 64px;
            }

            .zoom-slider {
              width: 80px;
            }
          }

          @media (min-width: 768px) {
            .images-comparison {
              flex-direction: row;
            }

            .image-section {
              max-width: 50%;
            }
          }
      `}</style>
    </div>
  );
};

export default CustomWebcam;